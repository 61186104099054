import axios from "axios";
import {FormObject} from "@/utils/form-object";
import {
    ADD_RIGHT,
    CREATE_PROJECT,
    DELETE_PROJECT,
    DELETE_RIGHT,
    GET_PROJECT,
    PAY,
    SAVE_PROJECT,
    SAVE_RIGHT
} from "../actions";
import {
    POP_PROJECT,
    POP_RIGHT,
    PUSH_LICENSE,
    PUSH_PROJECT,
    PUSH_RIGHT,
    SET_CALENDARS,
    SET_INTEGRATIONS,
    SET_LICENSES,
    SET_PROJECT,
    SET_PROJECTS,
    SET_RIGHT,
    SET_RIGHTS,
    SET_SCENARIOS
} from "../mutations";

const state = {
    project: new FormObject(),
    projects: [],
    rights: [],
    right: {},
    licenses: [],
};

const getters = {
    project: state => state.project || new FormObject(),
    projects: state => state.projects || [],
    rights: state => state.rights || [],
    right: state => state.right || {},
    licenses: state => state.licenses || [],
};

const actions = {
    [GET_PROJECT]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${id}`,
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_PROJECT, resp.data.data);
                    commit(SET_INTEGRATIONS, resp.data.data.integrations);
                    commit(SET_SCENARIOS, resp.data.data.scenarios);
                    commit(SET_CALENDARS, resp.data.data.calendars);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [CREATE_PROJECT]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_PROJECT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SAVE_PROJECT]: ({commit}, {id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + id,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_PROJECT, resp.data.data);
                    commit(SET_PROJECT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_PROJECT]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + id,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_PROJECT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_RIGHT]: ({commit}, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + project_id + '/right',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_RIGHT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SAVE_RIGHT]: ({commit}, {project_id, right_id, right}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + project_id + '/right/' + right_id,
                method: 'PUT',
                data: right,
            })
                .then(resp => {
                    commit(PUSH_RIGHT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_RIGHT]: ({commit}, {project_id, right_id}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + project_id + '/right/' + right_id,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_RIGHT, resp.data.data);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PAY]: (_, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/project/' + project_id + '/pay',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_PROJECT]: (state, project) => {
        if (project) {
            state.project = new FormObject({
                id: project.id,
                name: project.name,
            })
        } else {
            state.project = new FormObject()
        }
    },
    [SET_PROJECTS]: (state, projects) => {
        state.projects = projects.sort(function (a, b) {
            return (a.id - b.id);
        });
    },
    [PUSH_PROJECT]: (state, project) => {
        let index = state.projects.findIndex((item) => item.id === project.id);
        if (index >= 0) {
            state.projects[index] = project;
        } else {
            state.projects.push(project);
        }
    },
    [POP_PROJECT]: (state, id) => {
        let index = state.projects.findIndex((item) => item.id === id);
        if (index >= 0) {
            state.projects.splice(index, 1);
        }
    },
    [SET_RIGHT]: (state, right) => {
        state.right = right;
    },
    [SET_RIGHTS]: (state, rights) => {
        state.rights = rights.sort(function (a, b) {
            return (a.id - b.id);
        });
    },
    [PUSH_RIGHT]: (state, right) => {
        let index = state.rights.findIndex((item) => item.user_id === right.user_id);
        if (index >= 0) {
            state.rights[index] = right;
        } else {
            state.rights.push(right);
        }
    },
    [POP_RIGHT]: (state, right_id) => {
        let index = state.rights.findIndex((item) => item.id === right_id);
        if (index >= 0) {
            state.rights.splice(index, 1);
        }
    },
    [SET_LICENSES]: (state, licenses) => {
        state.licenses = licenses.sort(function (a, b) {
            return (a.id - b.id);
        })
    },
    [PUSH_LICENSE]: (state, license) => {
        let index = state.licenses.findIndex((item) => item.id === license.id);
        if (index >= 0) {
            state.licenses[index] = license;
        } else {
            state.licenses.push(license);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}