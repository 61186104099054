export class Errors {
    constructor(errors) {
        this.errors = errors || [];
    }

    record(errors) {
        this.errors = [];
        if (Array.isArray(errors)) {
            this.errors = errors;
        }
    }

    get(field) {
        for (let error of this.errors) {
            if (error.source && error.source.pointer === field) {
                return codes[error.code] || error.title;
            }
        }
    }

    filter(key) {
        let errors = this.errors.filter((e) => e.source.pointer.startsWith(key))
        errors.forEach((e) => {
            e.source.pointer = "342345"
            console.log(e.source.pointer)
        })
        return new Errors(errors)
    }

    clear() {
        this.errors = [];
    }
}

const codes = {
    "project_name": "Название проекта должно состоять из букв и цифр, не длиннее 20 символов. ",
    "email": "Проверьте правильность ввода электронной почты. ",
    "password": "Пароль должен быть не меньше 6 символов и состоять из цифр, латиницы. ",
    "eqfield": "Пароли не совпадают. ",
    "required": "Поле обязательно для заполнения. ",
}