<template>
  <el-row style="margin-top: 50px">
    <el-col :span="6" :offset="9">
      <el-card class="box-card" header="Регистрация">
        <el-form ref="registrationFormRef"
                 :model="registrationForm"
                 :rules="registrationFormRules"
                 label-position="top">
          <el-form-item label="Email" prop="value" :error="errors.get('value')">
            <el-input v-model="registrationForm.value"/>
          </el-form-item>
          <el-form-item label="Пароль" prop="password" :error="errors.get('password')">
            <el-input v-model="registrationForm.password" show-password/>
          </el-form-item>
          <el-form-item label="Подтвердите пароль" prop="password_confirm"
                        :error="errors.get('password_confirm')">
            <el-input v-model="registrationForm.password_confirm" show-password/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="registration" style="width: 100%"
                       :loading="loadingRegistrationForm">
              Зарегистрироваться
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="$router.push('/login')" style="width: 100%">
              Вход
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {Errors} from "@/utils/errors";
import {REGISTRATION} from "@/store/actions";

export default {
  name: 'LinkerRegistration',
  data() {
    return {
      loadingRegistrationForm: false,
      errors: new Errors(),
      registrationForm: {
        type: "email",
        value: "",
        password: "",
        password_confirm: "",
      },
      registrationFormRules: {
        value: [{required: true, message: 'Введите email', trigger: 'blur'}],
        password: [{required: true, message: 'Введите пароль', trigger: 'blur'}],
        password_confirm: [{required: true, message: 'Подтвердите пароль', trigger: 'blur'}],
      },
    }
  },
  methods: {
    registration() {
      this.errors.clear();
      this.$refs.registrationFormRef.validate((valid) => {
        if (valid) {
          this.loadingRegistrationForm = true;
          this.$store.dispatch(REGISTRATION, this.registrationForm).then(() => {
            this.loadingRegistrationForm = false;
            this.$router.push('/profile')
          }).catch((err) => {
            this.loadingRegistrationForm = false;
            this.errors.record(err.response.data.errors);
          })
        }
      });
    }
  },
}
</script>

<style scoped></style>
