export const SET_USER = 'SET_USER';
export const SET_CONTACTS = 'SET_CONTACTS';

export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT = 'SET_PROJECT';
export const PUSH_PROJECT = 'PUSH_PROJECT';
export const POP_PROJECT = 'POP_PROJECT';

export const SET_LICENSES = 'SET_LICENSES';
export const PUSH_LICENSE = 'PUSH_LICENSE';

export const SET_RIGHTS = 'SET_RIGHTS';
export const SET_RIGHT = 'SET_RIGHT';
export const PUSH_RIGHT = 'PUSH_RIGHT';
export const POP_RIGHT = 'POP_RIGHT';

export const SET_INTEGRATION = 'SET_INTEGRATION';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const PUSH_INTEGRATION = 'PUSH_INTEGRATION';
export const SET_AMOCRM_ACCOUNT = 'SET_AMOCRM_ACCOUNT';
export const SET_AMOCRM_CUSTOM_FIELDS = 'SET_AMOCRM_CUSTOM_FIELDS';

export const SET_SCENARIOS = 'SET_SCENARIOS';
export const PUSH_SCENARIO = 'PUSH_SCENARIO';
export const POP_SCENARIO = 'POP_SCENARIO';
export const SET_SCENARIO = 'SET_SCENARIO';
export const SET_ACTIONS = 'SET_ACTIONS';
export const PUSH_ACTION = 'PUSH_ACTION';

export const SET_CALENDARS = 'SET_CALENDARS';
export const SET_CALENDAR = 'SET_CALENDAR';
export const PUSH_CALENDAR = 'PUSH_CALENDAR';
export const POP_CALENDAR = 'POP_CALENDAR';

export const SET_CALENDAR_USERS = 'SET_CALENDAR_USERS';

export const SET_CALENDAR_SCHEDULE_USERS = 'SET_CALENDAR_SCHEDULE_USERS';
export const PUSH_CALENDAR_SCHEDULE_USER = 'PUSH_CALENDAR_SCHEDULE_USER';
export const POP_CALENDAR_SCHEDULE_USER = 'POP_CALENDAR_SCHEDULE_USER';

export const SET_CALENDAR_DISTRIBUTIONS = 'SET_CALENDAR_DISTRIBUTIONS';
export const PUSH_CALENDAR_DISTRIBUTION = 'PUSH_CALENDAR_DISTRIBUTION';
export const POP_CALENDAR_DISTRIBUTION = 'POP_CALENDAR_DISTRIBUTION';

export const SET_CALENDAR_SCHEDULES = 'SET_CALENDAR_SCHEDULES';
export const PUSH_CALENDAR_SCHEDULE = 'PUSH_CALENDAR_SCHEDULE';
export const POP_CALENDAR_SCHEDULE = 'POP_CALENDAR_SCHEDULE';





