<template>
  <el-card class="action-card" :header="header">
    <el-collapse v-model="collapse">
      <el-collapse-item v-for="(action, index) in valueActions.filter(a => a.condition===condition)"
                        :title="action.name" :key="action.id" name="1">
        <linker-action :action="action" @delAction="delAction(index)"/>
      </el-collapse-item>
    </el-collapse>
    <el-button type="" :icon="Plus" circle @click="addAction"/>
  </el-card>
</template>

<script setup>import {Plus} from '@element-plus/icons-vue'</script>

<script>
import LinkerAction from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerActions/LinkerAction";

export default {
  name: 'LinkerActions',
  components: {LinkerAction},
  props: {
    header: String,
    actions: Array,
    condition: Number,
  },
  data() {
    return {
      collapse: ['1']
    }
  },
  methods: {
    addAction() {
      this.valueActions.push({
        condition: this.condition,
        actions: []
      })
    },
    delAction(index) {
      this.valueActions.splice(index, 1);
    },
  },
  computed: {
    valueActions() {
      return this.actions || []
    },
  }
}
</script>

<style>
.action-card {
  margin: 10px 0;
}

.action-card .el-card__header {
  padding: 10px;
  background: aliceblue;

}

.action-card .el-card__body {
  padding: 5px;
}

.action-card .el-card__body .el-collapse {
  border: none;
}

.action-card .el-card__body .el-collapse .el-collapse-item {
  margin-bottom: 3px;
  border: solid 1px lightgrey;
  border-radius: 5px;
  padding: 0 7px;
}

.action-card .el-card__body .el-collapse .el-collapse-item .el-collapse-item__wrap {
  border: none;
}

.action-card .el-card__body .el-collapse-item .el-collapse-item__header {
  border: none;
  padding: 0;
}
</style>
