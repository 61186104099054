<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button type="primary" @click="openAddScheduleForm" size="small">Добавить график</el-button>
    </el-row>
    <el-row>
      <el-table style="width: 100%" :data="calendar_schedules">
        <el-table-column prop="id" label="ID" width="180"/>
        <el-table-column prop="name" label="Название" width="180"/>
        <el-table-column prop="amount_days" label="Количество дней" width="180"/>
        <el-table-column min-width="100" align="right">
          <template #default="scope">
            <el-button :icon="EditPen" circle @click="openEditScheduleForm(scope.row)"/>
            <el-popconfirm title="Удалить?"
                           confirm-button-text="Да"
                           confirm-button-type="danger"
                           cancel-button-text="Нет"
                           :icon="InfoFilled"
                           @confirm="deleteSchedule(scope.row.id)">
              <template #reference>
                <el-button type="danger" :icon="Delete" circle/>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-dialog
        v-model="calendarScheduleFormVisible"
        title="График"
        :width="700"
    >
      <el-form label-position="top"
               :model="calendarScheduleForm.object"
               :rules="calendarScheduleRules"
               ref="calendarScheduleRef" size="small">
        <el-row style="width: 100%;">
          <el-col>
            <el-form-item label="Название" prop="name" style="width: 100%;"
                          :error="errors.get('Name')">
              <el-input v-model="calendarScheduleForm.object.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="События" prop="events"
                      :error="errors.get('events')" class="events">
          <div class="timing">
            <div class="hours">
              <div v-for="h in 24" :key="h" class="hour" style="width: 4%">
                <span class="hour-title">{{ h - 1 }}</span>
              </div>
            </div>
            <div class="days">
              <div v-for="day_number in calendarScheduleForm.object.amount_days" :key="day_number" class="day"
                   @click="openAddEventForm(day_number)">
                {{day_number}}
                <div v-for="(event, index) in calendarScheduleForm.object.events" :key="index">
                  <div v-if="event.day_number === day_number" class="event"
                       :style="`left: ${100*event.start_offset/1440}%; width: ${100*(event.end_offset-event.start_offset)/1440}%`"
                       @click.stop="openEditEventForm(event, index)"/>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%; text-align: center">
            <el-button @click="calendarScheduleForm.object.amount_days++">Добавить день</el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-if="calendarScheduleForm.origin.id" type="primary" @click="editSchedule"
                     :disabled="calendarScheduleForm.isEqual()">Сохранить
          </el-button>
          <el-button v-else type="primary" @click="addSchedule">Добавить</el-button>
          <el-button @click="calendarScheduleFormVisible = false">Отмена</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        v-model="calendarScheduleEventFormVisible"
        title="Событие"
        :width="400"
    >
      <el-form label-position="top"
               ref="calendarScheduleEventRef"
               :rules="calendarScheduleEventRules"
               :model="calendarScheduleEventForm" size="small">
        <el-form-item label="Тип события" prop="type" style="width: 100%;"
                      :error="errors.get('Type')">
          <el-select v-model="calendarScheduleEventForm.type" size="small">
            <el-option
                v-for="(value,key) in eventTypes"
                :key="key"
                :label="value"
                :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Время события" prop="period"
                      :error="errors.get('period')" class="events">
          <el-time-picker
              v-model="calendarScheduleEventForm.period"
              is-range
              format="HH:mm"
              range-separator="-"
              start-placeholder="Начало"
              end-placeholder="Конец"
          />
        </el-form-item>

        <el-form-item>
          <el-button v-if="calendarScheduleEventForm.index >=0" type="primary" @click="addOrEditEvent">Сохранить
          </el-button>
          <el-button v-if="calendarScheduleEventForm.index >=0" type="danger"
                     @click="deleteEvent(calendarScheduleEventForm.index)">Удалить
          </el-button>
          <el-button v-else type="primary" @click="addOrEditEvent">Добавить</el-button>
          <el-button @click="calendarScheduleEventFormVisible = false">Отмена</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script setup>import {Delete, EditPen, InfoFilled} from '@element-plus/icons-vue'</script>

<script>
import {FormObject} from "@/utils/form-object";
import {Errors} from "@/utils/errors";
import {ADD_CALENDAR_SCHEDULE, DELETE_CALENDAR_SCHEDULE, UPDATE_CALENDAR_SCHEDULE} from "@/store/actions";

export default {
  name: 'LinkerCalendarSchedule',
  props: {
    calendar: Object,
  },
  data() {
    return {
      errors: new Errors(),
      calendarScheduleFormVisible: false,
      calendarScheduleForm: new FormObject({amount_days: 1}),
      calendarScheduleRules: {
        name: [{required: true, message: 'Введите название', trigger: 'blur'}],
        events: [{required: true, message: 'Необходимо добавить хотя бы одно событие', trigger: 'blur'}],
      },

      calendarScheduleEventForm: {},
      calendarScheduleEventFormVisible: false,
      calendarScheduleEventRules: {
        type: [{required: true, message: 'Выберите тип', trigger: 'blur'}],
        period: [{required: true, message: 'Укажите время события', trigger: 'blur'}],
      },

      eventTypes: {
        "work": "Рабочее время",
        "not-work": "Нерабочее время",
        "holiday": "Отпуск"
      },
    }
  },
  methods: {
    openAddScheduleForm() {
      this.calendarScheduleForm = new FormObject({amount_days: 1, events: []})
      this.calendarScheduleFormVisible = true
    },
    addSchedule() {
      this.$refs.calendarScheduleRef.validate((valid) => {
        if (valid) {
          let pid = this.calendar.project_id
          let cid = this.calendar.id
          let data = this.calendarScheduleForm.object
          this.$store.dispatch(ADD_CALENDAR_SCHEDULE, {pid, cid, data}).then(() => {
            this.calendarScheduleFormVisible = false
          })
        }
      })
    },
    openEditScheduleForm(schedule) {
      this.calendarScheduleForm = new FormObject(schedule)
      this.calendarScheduleFormVisible = true
    },
    editSchedule() {
      this.$refs.calendarScheduleRef.validate((valid) => {
        if (valid) {
          let pid = this.calendar.project_id
          let cid = this.calendar.id
          let csid = this.calendarScheduleForm.origin.id
          let data = this.calendarScheduleForm.object
          this.$store.dispatch(UPDATE_CALENDAR_SCHEDULE, {pid, cid, csid, data}).then(() => {
            this.calendarScheduleFormVisible = false
          })
        }
      })
    },
    deleteSchedule(csid) {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      this.$store.dispatch(DELETE_CALENDAR_SCHEDULE, {pid, cid, csid}).then()
    },
    openAddEventForm(day_number) {
      this.calendarScheduleEventForm = {
        index: -1,
        type: "work",
        period: [new Date(new Date().setHours(0, 540, 0, 0)), new Date(new Date().setHours(0, 1080, 0, 0))],
        day_number: day_number
      }
      this.calendarScheduleEventFormVisible = true
    },
    openEditEventForm(event, index) {
      this.calendarScheduleEventForm = {
        index: index,
        type: event.type,
        day_number: event.day_number,
        period: [new Date(new Date().setHours(0, event.start_offset, 0, 0)), new Date(new Date().setHours(0, event.end_offset, 0, 0))],
      }
      this.calendarScheduleEventFormVisible = true
    },
    deleteEvent(index) {
      if (index >= 0) {
        this.calendarScheduleForm.object.events.splice(index, 1);
      }
      this.calendarScheduleEventFormVisible = false
    },
    addOrEditEvent() {
      this.$refs.calendarScheduleEventRef.validate((valid) => {
        if (valid) {
          if (!Array.isArray(this.calendarScheduleForm.object.events)) {
            this.calendarScheduleForm.object.events = []
          }

          let start = this.calendarScheduleEventForm.period[0]
          let end = this.calendarScheduleEventForm.period[1]

          let event = {
            type: this.calendarScheduleEventForm.type,
            day_number: this.calendarScheduleEventForm.day_number,
            start_offset: start.getHours() * 60 + start.getMinutes(),
            end_offset: end.getHours() * 60 + end.getMinutes(),
          }

          if (this.calendarScheduleEventForm.index < 0) {
            this.calendarScheduleForm.object.events.push(event)
          } else {
            this.calendarScheduleForm.object.events[this.calendarScheduleEventForm.index] = event
          }
          this.calendarScheduleEventFormVisible = false
        }
      })
    },
  },
  computed: {
    calendar_schedules() {
      return this.$store.getters.calendar_schedules
    },
  }
}
</script>

<style>

.events .el-form-item__content {
  display: block !important;
}

.timing {
  position: relative;
  margin: 20px 0;
}

.timing .hours {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.timing .hours .hour {
  border-left: 1px solid #ddd;
  flex-grow: 1;
  text-align: center;
}

.timing .hours .hour .hour-title {
  position: relative;
  left: -50%;
  text-align: center;
  margin: 3px;
  background: white;
}

.timing .days {
  padding-top: 30px;
}

.timing .days .day {
  position: relative;
  border: 1px solid #ddd;
  min-height: 30px;
  cursor: pointer;
}

.timing .days .day + .day {
  border-top: none;
}

.timing .days .day:hover {
  background: aliceblue;
  opacity: 0.5;
}

.timing .days .day .event {
  position: absolute;
  height: 100%;
  background: chartreuse;
}

</style>
