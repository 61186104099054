<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (смена ответственного)</div>
    </template>
    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item label="Сущность" :error="errors.get(index + '.Data.Entity')">
        <el-select v-model="action.entity" size="small">
          <el-option
              v-for="item in entities"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Способ выбора ответственного"
                    :error="errors.get(index + '.Data.ResponsibleSelectionMethod')">
        <el-select v-model="action.responsible_selection_method" size="small">
          <el-option
              v-for="item in methods"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Пользователь"
                    v-if="action.responsible_selection_method === 'fixed'"
                    :error="errors.get(index + '.Data.ResponsibleUserId')">
        <el-select v-model="action.responsible_user_id" size="small">
          <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>
  </el-card>
</template>

<script setup>import {Handle, Position} from '@vue-flow/core'</script>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionAmocrmChangeResponsible',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      entities: [{
        value: 'found-contact',
        label: 'Найденный контакт'
      }, {
        value: 'found-lead',
        label: 'Найденная сделка'
      }],
      methods: [{
        value: 'fixed',
        label: 'Фиксированный пользователь'
      }, {
        value: 'calendar',
        label: 'Из календаря'
      }, {
        value: 'find_contact',
        label: 'Из найденного контакта'
      }],
    }
  },
  computed: {
    action() {
      return this.data
    },
    account() {
      return (this.$store.getters.integrations.find(i => i.type === this.scenario.target_type && i.id === this.scenario.target_id) || {}).account || {}
    },
    types() {
      return this.account.task_types || []
    },
    users() {
      return this.account.users || []
    },
  }
}
</script>
