import {
    POP_CALENDAR,
    POP_CALENDAR_DISTRIBUTION,
    POP_CALENDAR_SCHEDULE,
    POP_CALENDAR_SCHEDULE_USER,
    PUSH_CALENDAR,
    PUSH_CALENDAR_DISTRIBUTION,
    PUSH_CALENDAR_SCHEDULE,
    PUSH_CALENDAR_SCHEDULE_USER,
    SET_CALENDAR,
    SET_CALENDAR_DISTRIBUTIONS,
    SET_CALENDAR_SCHEDULE_USERS,
    SET_CALENDAR_SCHEDULES,
    SET_CALENDAR_USERS,
    SET_CALENDARS
} from "@/store/mutations";
import {
    ADD_CALENDAR,
    ADD_CALENDAR_DISTRIBUTION,
    ADD_CALENDAR_SCHEDULE,
    ADD_CALENDAR_USER_SCHEDULE,
    DELETE_CALENDAR,
    DELETE_CALENDAR_DISTRIBUTION,
    DELETE_CALENDAR_SCHEDULE,
    DELETE_CALENDAR_USER_SCHEDULE,
    GET_CALENDAR,
    SYNC_CALENDAR_AMOCRM_USERS,
    UPDATE_CALENDAR,
    UPDATE_CALENDAR_DISTRIBUTION,
    UPDATE_CALENDAR_SCHEDULE,
    UPDATE_CALENDAR_USER_SCHEDULE
} from "@/store/actions";
import axios from "axios";
import {FormObject} from "@/utils/form-object";

const state = {
    calendar: new FormObject(),
    calendars: [],
    calendar_users: [],
    calendar_schedules: [],
    calendar_schedule_users: [],
    calendar_distributions: [],
};

const getters = {
    calendar: state => state.calendar || new FormObject(),
    calendars: state => state.calendars || [],
    calendar_users: state => state.calendar_users || [],
    calendar_schedules: state => state.calendar_schedules || [],
    calendar_schedule_users: state => state.calendar_schedule_users || [],
    calendar_distributions: state => state.calendar_distributions || [],
};

const actions = {
    [GET_CALENDAR]: ({commit}, {pid, cid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}`,
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_CALENDAR, resp.data.data)
                    commit(SET_CALENDAR_USERS, resp.data.data.users)
                    commit(SET_CALENDAR_SCHEDULES, resp.data.data.schedules)
                    commit(SET_CALENDAR_SCHEDULE_USERS, resp.data.data.schedule_users)
                    commit(SET_CALENDAR_DISTRIBUTIONS, resp.data.data.distributions)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_CALENDAR]: ({commit}, {pid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [UPDATE_CALENDAR]: ({commit}, {pid, cid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    console.log("==========", resp.data.data)
                    commit(SET_CALENDAR, resp.data.data)
                    commit(PUSH_CALENDAR, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_CALENDAR]: ({commit}, {pid, cid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}`,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_CALENDAR, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SYNC_CALENDAR_AMOCRM_USERS]: ({commit}, {pid, cid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/user/sync`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(SET_CALENDAR_USERS, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_CALENDAR_DISTRIBUTION]: ({commit}, {pid, cid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/distribution`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_DISTRIBUTION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [UPDATE_CALENDAR_DISTRIBUTION]: ({commit}, {pid, cid, cdid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/distribution/${cdid}`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_DISTRIBUTION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_CALENDAR_DISTRIBUTION]: ({commit}, {pid, cid, cdid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/distribution/${cdid}`,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_CALENDAR_DISTRIBUTION, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_CALENDAR_SCHEDULE]: ({commit}, {pid, cid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/schedule`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_SCHEDULE, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [UPDATE_CALENDAR_SCHEDULE]: ({commit}, {pid, cid, csid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/schedule/${csid}`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_SCHEDULE, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_CALENDAR_SCHEDULE]: ({commit}, {pid, cid, csid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/schedule/${csid}`,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_CALENDAR_SCHEDULE, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    [ADD_CALENDAR_USER_SCHEDULE]: ({commit}, {pid, cid, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/scheduleUser`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_SCHEDULE_USER, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [UPDATE_CALENDAR_USER_SCHEDULE]: ({commit}, {pid, cid, id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/scheduleUser/${id}`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_CALENDAR_SCHEDULE_USER, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_CALENDAR_USER_SCHEDULE]: ({commit}, {pid, cid, id}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/calendar/${cid}/scheduleUser/${id}`,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_CALENDAR_SCHEDULE_USER, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_CALENDAR]: (state, calendar) => {
        state.calendar = new FormObject(calendar);
    },
    [SET_CALENDARS]: (state, calendars) => {
        state.calendars = calendars;
    },
    [PUSH_CALENDAR]: (state, calendar) => {
        let index = state.calendars.findIndex((item) => item.id === calendar.id);
        if (index >= 0) {
            state.calendars[index] = calendar;
        } else {
            state.calendars.push(calendar);
        }
    },
    [POP_CALENDAR]: (state, calendar) => {
        let index = state.calendars.findIndex((item) => item.id === calendar.id);
        if (index >= 0) {
            state.calendars.splice(index, 1);
        }
    },
    [SET_CALENDAR_SCHEDULE_USERS]: (state, calendar_schedule_users) => {
        state.calendar_schedule_users = calendar_schedule_users;
    },
    [PUSH_CALENDAR_SCHEDULE_USER]: (state, calendar_schedule_user) => {
        let index = state.calendar_schedule_users.findIndex((item) => item.id === calendar_schedule_user.id);
        if (index >= 0) {
            state.calendar_schedule_users[index] = calendar_schedule_user;
        } else {
            state.calendar_schedule_users.push(calendar_schedule_user);
        }
    },
    [POP_CALENDAR_SCHEDULE_USER]: (state, calendar_schedule_user_id) => {
        let index = state.calendar_schedule_users.findIndex((item) => item.id === calendar_schedule_user_id);
        if (index >= 0) {
            state.calendar_schedule_users.splice(index, 1);
        }
    },

    [SET_CALENDAR_USERS]: (state, calendar_users) => {
        state.calendar_users = calendar_users;
    },

    [SET_CALENDAR_DISTRIBUTIONS]: (state, calendar_distributions) => {
        state.calendar_distributions = calendar_distributions;
    },
    [PUSH_CALENDAR_DISTRIBUTION]: (state, calendar_distribution) => {
        let index = state.calendar_distributions.findIndex((item) => item.id === calendar_distribution.id);
        if (index >= 0) {
            state.calendar_distributions[index] = calendar_distribution;
        } else {
            state.calendar_distributions.push(calendar_distribution);
        }
    },
    [POP_CALENDAR_DISTRIBUTION]: (state, calendar_distribution) => {
        let index = state.calendar_distributions.findIndex((item) => item.id === calendar_distribution);
        if (index >= 0) {
            state.calendar_distributions.splice(index, 1);
        }
    },

    [SET_CALENDAR_SCHEDULES]: (state, calendar_schedules) => {
        state.calendar_schedules = calendar_schedules;
    },
    [PUSH_CALENDAR_SCHEDULE]: (state, calendar_schedule) => {
        let index = state.calendar_schedules.findIndex((item) => item.id === calendar_schedule.id);
        if (index >= 0) {
            state.calendar_schedules[index] = calendar_schedule;
        } else {
            state.calendar_schedules.push(calendar_schedule);
        }
    },
    [POP_CALENDAR_SCHEDULE]: (state, calendar_schedule) => {
        let index = state.calendar_schedules.findIndex((item) => item.id === calendar_schedule);
        if (index >= 0) {
            state.calendar_schedules.splice(index, 1);
        }
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}