<template>
  <div>
    {{ project }}
  </div>
</template>

<script>

export default {
  name: 'LinkerSetting',
  data() {
    return {}
  },
  methods: {},
  computed: {
    project() {
      return this.$store.getters.project
    }
  }
}
</script>

<style scoped>
</style>
