<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (поиск сделки)</div>
    </template>
    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item label="Фильтр по связанной сущности" :error="errors.get(index + '.Data.FilterByEntityRelated')">
        <el-select v-model="action.filter_by_entity_related" size="small">
          <el-option
              v-for="item in entities"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Фильтр по ответственному" :error="errors.get(index + '.Data.FilterByResponsibleUsers')">
        <el-select v-model="action.filter_by_responsible_users" size="small" multiple :collapse-tags="true">
          <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Фильтр по статусу" :error="errors.get(index + '.Data.FilterByStatuses')">
        <el-select v-model="action.filter_by_statuses" size="small" multiple value-key="key" :collapse-tags="true">
          <el-option-group
              v-for="pipeline in pipelines"
              :key="pipeline.id"
              :label="pipeline.name"
          >
            <el-option
                v-for="status in pipeline.statuses"
                :key="`${pipeline.id}:${status.id}`"
                :label="status.name"
                :value="{key:`${pipeline.id}:${status.id}`, pipeline_id:pipeline.id, status_id:status.id}"
            />
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="Способ выбора сделки" :error="errors.get(index + '.Data.SelectionMethod')">
        <el-select v-model="action.selection_method" size="small">
          <el-option
              v-for="item in methods"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="found"
        type="source"
        :position="Position.Right" style="top: 30%; background: green;"
        :connectable="1"/>
    <Handle
        id="not-found"
        type="source"
        :position="Position.Right" style="top: 70%; background: red;"
        :connectable="1"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>
  </el-card>
</template>

<script setup>import {Handle, Position} from '@vue-flow/core'</script>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionFindLead',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      methods: [{
        value: 'last-create',
        label: 'Последняя созданная'
      }, {
        value: 'last-update',
        label: 'Последняя обновленная'
      }],
      entities: [{
        value: 'found-contact',
        label: 'Найденный контакт'
      }]
    }
  },
  computed: {
    action() {
      return this.data
    },
    account() {
      return (this.$store.getters.integrations.find(i => i.type === this.scenario.target_type && i.id === this.scenario.target_id) || {}).account || {}
    },
    users() {
      return this.account.users || []
    },
    pipelines() {
      return this.account.pipelines || []
    },
    statuses() {
      return this.account.statuses || []
    },
  }
}
</script>

