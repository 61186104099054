<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button type="primary" @click="syncAmocrmUsersFormVisible=true" size="small">
        Синхронизировать пользователей с amoCRM
      </el-button>
    </el-row>
    <el-row>
      <el-table style="width: 100%" :data="calendar_users">
        <el-table-column prop="id" label="ID" width="180"/>
        <el-table-column prop="name" label="Название" width="300"/>
        <el-table-column prop="type" label="Тип"/>
      </el-table>
    </el-row>

    <el-dialog
        v-model="syncAmocrmUsersFormVisible"
        title="Синхронизация пользователей amoCRM"
        :width="500"
    >
      <el-form label-position="top">
        <el-form-item label="Аккаунт amoCRM" prop="amocrm_account" style="width: 100%;"
                      :error="errors.get('AmocrmAccount')">
          <el-select
              :model-value="syncAmocrmUsersForm.integration_type && syncAmocrmUsersForm.integration_id ? `${syncAmocrmUsersForm.integration_type}:${syncAmocrmUsersForm.integration_id}` : ''"
              @change="syncAmocrmUsersForm.integration_type=$event.split(':')[0]; syncAmocrmUsersForm.integration_id=$event.split(':')[1]">
            <el-option
                v-for="integration in amocrm_integrations"
                :key="`${integration.type}:${integration.id}`"
                :label="integration.name + ' (' + integration.type + ')'"
                :value="`${integration.type}:${integration.id}`"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="syncAmocrmUsers">Синхронизировать</el-button>
          <el-button @click="syncAmocrmUsersFormVisible = false">Отмена</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {SYNC_CALENDAR_AMOCRM_USERS} from "@/store/actions";
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerCalendarUser',
  props: {
    calendar: Object,
  },
  data() {
    return {
      errors: new Errors(),
      syncAmocrmUsersFormVisible: false,
      syncAmocrmUsersForm: {
        integration_type: "",
        integration_id: "",
      },
    }
  },
  methods: {
    syncAmocrmUsers() {
      let cid = this.calendar.id
      let pid = this.calendar.project_id
      let data = this.syncAmocrmUsersForm
      this.$store.dispatch(SYNC_CALENDAR_AMOCRM_USERS, {pid, cid, data}).then(() => {
        this.syncAmocrmUsersFormVisible = false
      })
    }
  },
  computed: {
    calendar_users() {
      return this.$store.getters.calendar_users
    },
    amocrm_integrations() {
      return this.$store.getters.integrations.filter(i => ["amocrm"].includes(i.type))
    },
  }
}
</script>

<style></style>
