<template>
  <div>
    <el-form-item label="Фильтр по связанной сущности">
      <el-select v-model="valueAction.action_amocrm_find_lead.filter_by_related_entity">
        <el-option
            v-for="entity in entities"
            :key="entity.key"
            :label="entity.label"
            :value="entity.key"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Поиск по email">
      <el-select v-model="valueAction.action_amocrm_find_lead.selection_method">
        <el-option
            v-for="entity in selection_methods"
            :key="entity.key"
            :label="entity.label"
            :value="entity.key"
        />
      </el-select>
    </el-form-item>

    <linker-actions header="Контакт не найден" :actions="valueAction.actions"
                    :condition="0"/>
    <linker-actions header="Контакт найден" :actions="valueAction.actions" :condition="1"/>
  </div>
</template>

<script>
import LinkerActions from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerActions";

export default {
  name: 'LinkerActionFindLead',
  components: {LinkerActions},
  props: ["action"],
  data() {
    return {
      entities: [
        {
          key: "find_contact",
          label: "Найденный контакт",
        },
        {
          key: "create_contact",
          label: "Созданный контакт",
        }
      ],
      selection_methods: [
        {
          key: "last_create",
          label: "Последняя созданная",
        },
        {
          key: "last_update",
          label: "Последняя обновленная",
        }
      ],
    }
  },
  computed: {
    valueAction() {
      let value = this.action
      if (!Object.prototype.hasOwnProperty.call(value, "action_amocrm_find_lead")) {
        value.action_amocrm_find_lead = {}
      }
      return value
    },
  }
}
</script>

<style>
</style>
