import {createStore} from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import integration from './modules/integration'
import project from './modules/project'
import scenario from './modules/scenario'
import calendar from './modules/calendar'

export default createStore({
    modules: {
        auth,
        user,
        integration,
        project,
        scenario,
        calendar,
    }
})
