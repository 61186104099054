<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (валидация ответственного)</div>
    </template>
    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item label="Сущность" :error="errors.get(index + '.Data.Entity')">
        <el-select v-model="action.entity" size="small">
          <el-option
              v-for="item in entities"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Период определения, ч" :error="errors.get(index + '.Data.Period')">
        <el-input-number v-model="action.period" :controls="false" size="small"/>
      </el-form-item>
      <el-form-item label="Календарь"
                    :error="errors.get(index + '.Data.CalendarId')">
        <el-select v-model="action.calendar_id" size="small">
          <el-option
              v-for="item in calendars"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Распределение"
                    :error="errors.get(index + '.Data.DistributionId')">
        <el-select v-model="action.distribution_id" size="small">
          <el-option
              v-for="item in distributions(action.calendar_id)"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="work"
        type="source"
        :position="Position.Right" style="top: 20%; background: green;"
        :connectable="1"/>
    <Handle
        id="not-work"
        type="source"
        :position="Position.Right" style="top: 40%; background: blue;"
        :connectable="1"/>
    <Handle
        id="holiday"
        type="source"
        :position="Position.Right" style="top: 60%; background: orange;"/>
    <Handle
        id="delete"
        type="source"
        :position="Position.Right" style="top: 80%; background: red;"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>

  </el-card>
</template>


<script setup>import {Handle, Position} from '@vue-flow/core'</script>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionCalendarValidateResponsible',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      distribution_types: [{
        value: 'fixed',
        label: 'Фиксированный пользователь'
      }, {
        value: 'calendar',
        label: 'Календарь'
      }],
      entities: [{
        value: 'found-contact',
        label: 'Найденный контакт'
      }, {
        value: 'found-lead',
        label: 'Найденная сделка'
      }],
    }
  },
  methods: {
    distributions(calendar_id) {
      return (this.calendars.find(calendar => calendar.id === calendar_id) || {}).distributions || []
    }
  },
  computed: {
    action() {
      return this.$props.data
    },
    calendars() {
      return this.$store.getters.calendars
    },
  }
}
</script>

