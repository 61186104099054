<template>
  <div>
    <el-row style="margin-bottom: 15px">
      <el-col>
        <el-button type="primary" @click="openAddScenarioForm">Добавить календарь</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table style="width: 100%" :data="calendars">
          <el-table-column prop="id" label="ID" width="180"/>
          <el-table-column prop="name" label="Название" width="180"/>
          <el-table-column min-width="100" align="right">
            <template #default="scope">
              <el-button :icon="EditPen" circle @click="openEditCalendarForm(scope.row)"/>
              <el-popconfirm title="Удалить?"
                             confirm-button-text="Да"
                             confirm-button-type="danger"
                             cancel-button-text="Нет"
                             :icon="InfoFilled"
                             @confirm="deleteScenario(scope.row.id)">
                <template #reference>
                  <el-button type="danger" :icon="Delete" circle/>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="addCalendarFormVisible"
        title="Добавление календаря"
        :width="500"
    >
      <el-form label-position="top" ref="addCalendarFormRef" :model="addCalendarForm" :rules="addCalendarRules">
        <el-form-item label="Название календаря" prop="name" :error="errors.get('name')">
          <el-input v-model="addCalendarForm.name"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addCalendar">Добавить</el-button>
          <el-button @click="addCalendarFormVisible=false">Отмена</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        v-model="editCalendarFormVisible"
        title="Редактирование календаря"
        :width="1200"
    >
      <el-row>
        <el-form size="small" :model="calendar.object">
          <el-form-item label="Название">
            <el-col :span="16">
              <el-input v-model="calendar.object.name"/>
            </el-col>
            <el-col :span="8" style="padding-left: 20px">
              <el-button type="primary" :disabled="calendar.isEqual()" @click="updateScenario">Сохранить</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row>
        <el-tabs type="border-card" style="width: 100%">
          <el-tab-pane label="Календарь">
            <linker-calendar-schedule-user :calendar="calendar.origin"/>
          </el-tab-pane>
          <el-tab-pane label="Пользователи">
            <linker-calendar-user :calendar="calendar.origin"/>
          </el-tab-pane>
          <el-tab-pane label="Графики">
            <linker-calendar-schedule :calendar="calendar.origin"/>
          </el-tab-pane>
          <el-tab-pane label="Распределение">
            <linker-calendar-distribution :calendar="calendar.origin"/>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-dialog>
  </div>
</template>

<script setup>import {Delete, EditPen, InfoFilled} from '@element-plus/icons-vue'</script>

<script>
import {Errors} from "@/utils/errors";
import {ADD_CALENDAR, DELETE_CALENDAR, GET_CALENDAR, UPDATE_CALENDAR} from "@/store/actions";
import {FormObject} from "@/utils/form-object";
import '@vue-flow/core/dist/style.css'
import "@vue-flow/core/dist/theme-default.css";
import LinkerCalendarSchedule from "@/components/LinkerMain/LinkerProject/LinkerCalendar/LinkerCalendarSchedule";
import LinkerCalendarUser from "@/components/LinkerMain/LinkerProject/LinkerCalendar/LinkerCalendarUser";
import LinkerCalendarDistribution
  from "@/components/LinkerMain/LinkerProject/LinkerCalendar/LinkerCalendarDistribution";
import LinkerCalendarScheduleUser
  from "@/components/LinkerMain/LinkerProject/LinkerCalendar/LinkerCalendarScheduleUser";

export default {
  name: 'LinkerCalendar',
  components: {
    LinkerCalendarScheduleUser,
    LinkerCalendarSchedule,
    LinkerCalendarDistribution, LinkerCalendarUser
  },
  data() {
    return {
      errors: new Errors(),
      addCalendarFormVisible: false,
      editCalendarFormVisible: false,
      addCalendarForm: {
        name: "",
      },
      addCalendarRules: {
        name: [{required: true, message: 'Введите название', trigger: 'blur'}],
      },
      calendarForm: new FormObject(),
    }
  },
  methods: {
    openAddScenarioForm() {
      this.errors.clear()
      this.addCalendarForm = {}
      this.addCalendarFormVisible = true;
    },
    addCalendar() {
      this.errors.clear()
      this.$refs.addCalendarFormRef.validate((valid) => {
        if (valid) {
          let pid = this.$route.params.project_id
          let data = this.addCalendarForm
          this.$store.dispatch(ADD_CALENDAR, {pid, data}).then((resp) => {
            this.addCalendarFormVisible = false;
            this.openEditCalendarForm(resp.data.data)
          }).catch((err) => {
            this.errors.record(err.response.data.errors);
          })
        }
      });
    },
    openEditCalendarForm(calendar) {
      this.errors.clear()
      let cid = calendar.id
      let pid = calendar.project_id
      this.$store.dispatch(GET_CALENDAR, {pid, cid}).then(() => {
        this.editCalendarFormVisible = true;
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    updateScenario() {
      this.errors.clear()
      this.$store.dispatch(UPDATE_CALENDAR, {
        pid: this.$route.params.project_id,
        cid: this.calendar.origin.id,
        data: this.calendar.object,
      }).then(() => {
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    deleteScenario(cid) {
      let pid = this.$route.params.project_id
      this.$store.dispatch(DELETE_CALENDAR, {pid, cid}).then(() => {
      })
    },
  },
  computed: {
    calendars() {
      return this.$store.getters.calendars
    },
    calendar() {
      return this.$store.getters.calendar
    }
  }
}
</script>

<style>
.dialog-scenario {
  margin-top: 50px;
  height: 90%
}

.dialog-scenario .el-dialog__body {
  height: calc(100vh - 165px);
}

.flow-border {
  border: solid 1px lightgrey;
  border-radius: 5px;
}
</style>
