<template>
  <div>
    <el-row>
      <el-col :span="18" :offset="3">
        <el-card>
          <router-view/>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {GET_PROJECT, GET_USER} from "@/store/actions";

export default {
  name: 'LinkerMain',
  methods: {
    getUser() {
      this.$store.dispatch(GET_USER).then(() => {
        let project_id = this.$route.params.project_id;
        if (project_id) {
          this.$store.dispatch(GET_PROJECT, project_id).then().catch()
        }
      })
    }
  },
  beforeMount() {
    this.getUser();
  }
}
</script>

<style scoped>
</style>
