import {POP_SCENARIO, PUSH_ACTION, PUSH_SCENARIO, SET_ACTIONS, SET_SCENARIO, SET_SCENARIOS} from "@/store/mutations";
import {ADD_SCENARIO, DELETE_SCENARIO, GET_SCENARIO, UPDATE_SCENARIO} from "@/store/actions";
import axios from "axios";

const state = {
    scenarios: [],
    scenario: {},
    actions: [],
};

const getters = {
    scenarios: state => state.scenarios || [],
    scenario: state => state.scenario || {},
    actions: state => state.actions || [],
};

const actions = {
    [GET_SCENARIO]: ({commit}, {pid, sid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/scenario/${sid}`,
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_SCENARIO, resp.data.data)
                    commit(SET_ACTIONS, resp.data.data.actions)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [ADD_SCENARIO]: ({commit}, {project_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/scenario`,
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_SCENARIO, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [UPDATE_SCENARIO]: ({commit}, {project_id, scenario_id, data}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${project_id}/scenario/${scenario_id}`,
                method: 'PUT',
                data: data,
            })
                .then(resp => {
                    commit(PUSH_SCENARIO, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [DELETE_SCENARIO]: ({commit}, {pid, sid}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + `/api/project/${pid}/scenario/${sid}`,
                method: 'DELETE',
            })
                .then(resp => {
                    commit(POP_SCENARIO, resp.data.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_SCENARIOS]: (state, scenarios) => {
        state.scenarios = scenarios;
    },
    [PUSH_SCENARIO]: (state, scenario) => {
        let index = state.scenarios.findIndex((item) => item.id === scenario.id);
        if (index >= 0) {
            state.scenarios[index] = scenario;
        } else {
            state.scenarios.push(scenario);
        }
    },
    [POP_SCENARIO]: (state, scenario) => {
        let index = state.scenarios.findIndex((item) => item.id === scenario.id);
        if (index >= 0) {
            state.scenarios.splice(index, 1);
        }
    },
    [SET_SCENARIO]: (state, scenario) => {
        state.scenario = scenario
    },
    [SET_ACTIONS]: (state, actions) => {
        state.actions = actions
    },
    [PUSH_ACTION]: (state, action) => {
        let index = state.actions.findIndex((item) => item.id === action.id);
        if (index >= 0) {
            state.actions[index] = action;
        } else {
            state.actions.push(action);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}