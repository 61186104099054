<template>
  <el-form>
    <el-form-item>
      <el-input/>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'LinkerProject'
}
</script>

<style scoped>
</style>
