<template>
  <el-tabs type="border-card" v-model="a">
    <el-tab-pane label="Настройки проекта">
      <linker-setting/>
    </el-tab-pane>
    <el-tab-pane label="Интеграции">
      <linker-integration/>
    </el-tab-pane>
    <el-tab-pane label="Сценарии">
      <linker-scenario/>
    </el-tab-pane>
    <el-tab-pane label="Календари">
      <linker-calendar/>
    </el-tab-pane>
    <el-tab-pane label="Права доступа">Role</el-tab-pane>
  </el-tabs>
</template>

<script>
import LinkerIntegration from "@/components/LinkerMain/LinkerProject/LinkerIntegration";
import LinkerSetting from "@/components/LinkerMain/LinkerProject/LinkerSetting";
import LinkerScenario from "@/components/LinkerMain/LinkerProject/LinkerScenario";
import LinkerCalendar from "@/components/LinkerMain/LinkerProject/LinkerCalendar";

export default {
  name: 'LinkerProject',
  components: {LinkerCalendar, LinkerScenario, LinkerSetting, LinkerIntegration},
  data() {
    return {a: "2"}
  }
}
</script>

<style scoped>
</style>
