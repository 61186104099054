<template>
  <div>
    <el-form-item label="Название">
      <el-input v-model="valueAction.name"/>
    </el-form-item>

    <el-form-item label="Тип действия">
      <el-select v-model="valueAction.type">
        <el-option
            v-for="item in types"
            :key="item.type"
            :label="item.label"
            :value="item.type">
        </el-option>
      </el-select>
    </el-form-item>

    <linker-action-find-contact v-if="action.type==='AmocrmFindContact'" :action="valueAction"/>
    <linker-action-find-lead v-if="action.type==='AmocrmFindLead'" :action="valueAction"/>


    <el-button @click="$emit('delAction')">Del</el-button>
  </div>
</template>

<script>

import LinkerActionFindContact
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerActions/LinkerAction/LinkerActionFindContact";
import LinkerActionFindLead
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerActions/LinkerAction/LinkerActionFindLead";

export default {
  name: 'LinkerAction',
  components: {LinkerActionFindLead, LinkerActionFindContact},
  props: ["action", "delAction"],
  data() {
    return {
      types: [{
        type: "AmocrmFindContact",
        label: "Поиск контакта"
      }, {
        type: "AmocrmFindLead",
        label: "Поиск сделки"
      }]
    }
  },
  computed: {
    valueAction() {
      let value = this.action
      if (!Object.prototype.hasOwnProperty.call(value, "actions") || !Array.isArray(value.actions)) {
        value.actions = []
      }
      return value
    },
  }
}
</script>

<style>
</style>
