<template>
  <el-container>
    <el-header class="header">
      <linker-header/>
    </el-header>
    <el-main>
      <router-view/>
    </el-main>
    <el-footer class="footer">
    </el-footer>
  </el-container>
</template>

<script>

import LinkerHeader from "@/components/LinkerHeader";


export default {
  name: 'App',
  components: {LinkerHeader},
}
</script>

<style>
body {
  margin: 0;
}

.header {
  padding: 0 !important;
  background: darkslateblue;
}

.footer {
  background: darkslateblue;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0;
}
</style>
