import {createRouter, createWebHistory} from 'vue-router'
import LinkerMain from "@/components/LinkerMain";
import LinkerProject from "@/components/LinkerMain/LinkerProject";
import LinkerIntegration from "@/components/LinkerMain/LinkerProject/LinkerIntegration";
import LinkerProfile from "@/components/LinkerMain/LinkerProfile";
import LinkerLogin from "@/components/LinkerLogin";
import LinkerRegistration from "@/components/LinkerRegistration";


const routes = [
    {
        path: '/login',
        name: 'LinkerLogin',
        component: LinkerLogin,
    },
    {
        path: '/registration',
        name: 'LinkerRegistration',
        component: LinkerRegistration,
    },
    {
        path: '/',
        name: 'LinkerMain',
        component: LinkerMain,
        children: [
            {
                path: '/project/:project_id',
                name: 'NexusProject',
                component: LinkerProject,
                children: [
                    {
                        path: '/integration',
                        name: 'LinkerIntegration',
                        component: LinkerIntegration
                    }
                ]
            },
            {
                path: '/profile',
                name: 'LinkerProfile',
                component: LinkerProfile,
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router
