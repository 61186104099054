<template>
  <el-menu
      mode="horizontal"
      background-color="darkslateblue"
      text-color="#fff"
      active-text-color="#fff"
      :ellipsis="false"
      style="padding: 0 100px"
  >
    <el-menu-item index="1" @click="$router.push('/project/1')">Проект</el-menu-item>
    <el-sub-menu index="3">
      <template #title>Пользователь</template>
      <el-menu-item index="3-1" @click="$router.push('/profile')">Профиль</el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/login')">Выйти</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script>
export default {
  name: 'LinkerHeader',
}
</script>

<style scoped>
</style>
