<template>
  <div>
    <el-calendar class="calendar-wrap">
      <template #date-cell="{ data }">
        <div @click.stop="openUserScheduleForDayForm(data.day)" class="calendar-day-wrap">
          <div style="padding: 5px"> {{ new Date(data.day).getDate() }}</div>
          <div class="users">
            <div v-for="(events, user_id) in getUserEvents(data.day)" :key="user_id" class="user"
                 @click.stop="openUserScheduleList(user_id)">
              <span class="user-name">{{ getUserById(user_id).name }}</span>
              <span class="user-events">
              <span v-for="(event, key) in events" class="user-event" :key="key"
                    :style="`left: ${event.start/14.4}%; width: ${(event.end - event.start)/14.4}%; background: ${colorEventType[event.type]||''}`"></span>
              </span>
            </div>
          </div>
        </div>

      </template>
    </el-calendar>

    <el-dialog v-model="visibleUserScheduleForm"
               title="Добавление пользовательского графика"
               :width="500">
      {{ userScheduleForm }}
      <el-form :model="userScheduleForm" label-position="top" size="small">
        <el-form-item label="Пользователь" prop="user_id" :error="errors.get('UserId')">
          <el-select
              v-model="userScheduleForm.object.user_id">
            <el-option
                v-for="user in users"
                :key="user.id"
                :label="user.name"
                :value="user.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="График" prop="schedule_id" :error="errors.get('ScheduleId')">
          <el-select
              v-model="userScheduleForm.object.schedule_id">
            <el-option
                v-for="schedule in schedules"
                :key="schedule.id"
                :label="schedule.name"
                :value="schedule.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Начало графика" prop="start" :error="errors.get('Start')">
          <el-date-picker v-model="userScheduleForm.object.start"/>
        </el-form-item>
        <el-form-item label="Конец графика" prop="end" :error="errors.get('End')">
          <el-date-picker v-model="userScheduleForm.object.end"
                          :default-time="new Date(2000, 2, 1, 23, 59, 59)"/>
        </el-form-item>
        <el-form-item label="Первый день графика" prop="schedule_start" :error="errors.get('ScheduleStart')">
          <el-date-picker v-model="userScheduleForm.object.schedule_start"/>
        </el-form-item>

        <el-form-item>
          <el-button @click="visibleUserScheduleForm=false">Отмена</el-button>
          <el-button v-if="userScheduleForm.origin.id" type="primary" @click="updateUserSchedule">Сохранить</el-button>
          <el-button v-else type="primary" @click="addUserSchedule">Добавить</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="visibleUserScheduleList"
               :title="currentUser.name"
               :width="600">
      <el-row>
        <el-table :data="current_schedules_user" style="width: 100%;">
          <el-table-column prop="id" label="График" width="120">
            <template #default="scope">
              {{ getScheduleById(scope.row.schedule_id) }}
            </template>
          </el-table-column>
          <el-table-column label="Начало" width="100">
            <template #default="scope">
              {{ (new Date(scope.row.start)).toLocaleDateString() }}
            </template>
          </el-table-column>
          <el-table-column label="Конец" min-width="100">
            <template #default="scope">
              {{ (new Date(scope.row.end)).toLocaleDateString() }}
            </template>
          </el-table-column>
          <el-table-column min-width="100">
            <template #default="scope">
              <el-button :icon="EditPen" circle @click="openUserScheduleForm(scope.row)" size="small"/>
              <el-button type="danger" :icon="Delete" circle @click="deleteUserSchedule(scope.row.id)" size="small"/>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 20px; text-align: center; width: 100%; display: block">
        <el-button type="primary" @click="openUserScheduleForUserForm(currentUser.id)" size="small">Добавить</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script setup>import {Delete, EditPen} from '@element-plus/icons-vue'</script>

<script>

import {FormObject} from "@/utils/form-object";
import {
  ADD_CALENDAR_USER_SCHEDULE,
  DELETE_CALENDAR_USER_SCHEDULE,
  UPDATE_CALENDAR_USER_SCHEDULE
} from "@/store/actions";
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerCalendarScheduleUser',
  props: {
    calendar: Object,
  },
  data() {
    return {
      errors: new Errors(),
      visibleUserScheduleForm: false,
      userScheduleForm: new FormObject(),
      visibleUserScheduleList: false,
      currentUser: {},
      userScheduleList: {},
      colorEventType: {
        "work": "lawngreen",
        "not-work": "red",
        "holiday": "orange",
      }
    }
  },
  methods: {
    openUserScheduleList(user_id) {
      this.currentUser = this.getUserById(user_id)
      this.visibleUserScheduleList = true
    },
    addUserSchedule() {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      let data = this.userScheduleForm.object
      this.$store.dispatch(ADD_CALENDAR_USER_SCHEDULE, {pid, cid, data}).then(() => {
        this.visibleUserScheduleForm = false
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    updateUserSchedule() {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      let id = this.userScheduleForm.origin.id
      let data = this.userScheduleForm.object
      this.$store.dispatch(UPDATE_CALENDAR_USER_SCHEDULE, {pid, cid, id, data}).then(() => {
        this.visibleUserScheduleForm = false
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    deleteUserSchedule(id) {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      this.$store.dispatch(DELETE_CALENDAR_USER_SCHEDULE, {pid, cid, id}).then(() => {
      })
    },
    openUserScheduleForUserForm(user_id) {
      this.openUserScheduleForm({
        "user_id": user_id,
      })
    },
    getTimezoneOffset(date) {
      const tzOffset = -date.getTimezoneOffset();
      const diff = tzOffset >= 0 ? '+' : '-';
      return diff + `${Math.floor(Math.abs(tzOffset / 60))}`.padStart(2, '0') + ':' + `${Math.floor(Math.abs(tzOffset % 60))}`.padStart(2, '0');
    },
    openUserScheduleForDayForm() {
      // let now = new Date(new Date(day).setHours(0, 0, 0, 0))
      // let start = now.toISOString().split(".")[0] + this.getTimezoneOffset(now)
      this.openUserScheduleForm({
        //"start": start,
        //"schedule_start": start,
      })
    },
    openUserScheduleForm(user_schedule) {
      this.userScheduleForm = new FormObject(user_schedule)
      this.visibleUserScheduleForm = true
    },
    getUserEvents(day) {
      let user_events = {}
      let current = this.dateUTC(new Date(day))

      this.schedule_users.forEach((schedule_user) => {
        let schedule_start = new Date(schedule_user.schedule_start)
        let start = new Date(schedule_user.start)
        let end = new Date(schedule_user.end)

        let events = []
        this.schedules.filter(schedule => schedule.id === schedule_user.schedule_id).forEach((schedule) => {
          console.log("==========================================================", end, end.getTime())
          if (start.getTime() <= current.getTime() && (current.getTime() <= end.getTime() || end.getTime() < 0)) {
            let day_number = (current.getTime() - schedule_start.getTime()) / (24 * 60 * 60 * 1000) % schedule.amount_days + 1
            console.log("==========================================================", day_number)
            schedule.events.filter(event => event.day_number === day_number).forEach((event) => {
              events.push({start: event.start_offset, end: event.end_offset, type: event.type})
            })
          }
        })

        if (!Object.hasOwn(user_events, schedule_user.user_id) || !Array.isArray(user_events[schedule_user.user_id])) {
          user_events[schedule_user.user_id] = []
        }
        events.forEach((event) => {
          user_events[schedule_user.user_id].push(event)
        })
      })
      return user_events
    },
    dateUTC(date) {
      return new Date(new Date(`${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`).setHours(0, 0, 0, 0))
    },
    getUserById(id) {
      return this.users.find(user => user.id === parseInt(id)) || {}
    },
    getScheduleById(id) {
      return (this.schedules.find(user => user.id === parseInt(id)) || {}).name || "График был удален"
    }
  },
  computed: {
    schedule_users() {
      return this.$store.getters.calendar_schedule_users
    },
    current_schedules_user() {
      return this.$store.getters.calendar_schedule_users.filter(s => s.user_id === parseInt(this.currentUser.id))
    },
    schedules() {
      return this.$store.getters.calendar_schedules
    },
    users() {
      return this.$store.getters.calendar_users
    },
  }
}
</script>

<style>
.calendar-wrap .el-calendar-day {
  padding: 0;
  height: auto;
}

.calendar-wrap .el-calendar-day .calendar-day-wrap {
  width: 100%;
  height: 100%;
}


.calendar-wrap .el-calendar-day .calendar-day-wrap .users {
  padding: 0 5px;
}

.user {
  background: #f5f5f5;
  border-radius: 3px;
  margin-bottom: 1px;
}

.user-name {
  display: block;
  position: absolute;
  line-height: 20px;
  margin-left: 5px;
  z-index: 1000;
}

.user-events {
  width: 100%;
  height: 18px;
  position: relative;
  display: block;
}

.user-event {
  height: 100%;
  position: absolute;
  width: 10px;
  background: white;
  opacity: 0.5;
}
</style>
