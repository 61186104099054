<template>

  <div style="width: 100%; height: 100%">
    <VueFlow :default-viewport="{ zoom: 0.8 }" :nodes="nodesValue" :edges="edgesValue"
             :connection-mode="ConnectionMode.Strict">

      <Controls/>

      <Panel position="top-left">
        <el-dropdown>
          <el-button>
            Добавить
            <el-icon style="margin-left: 7px">
              <Plus/>
            </el-icon>
          </el-button>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="addAction('amocrm-find-contact')">Поиск контакта</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-find-lead')">Поиск сделки</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-find-task')">Поиск задачи</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-create-contact')">Создание контакта</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-create-lead')">Создание сделки</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-create-task')">Создание задачи</el-dropdown-item>
              <el-dropdown-item @click="addAction('amocrm-change-responsible')">Смена ответственного</el-dropdown-item>
              <el-dropdown-item @click="addAction('calendar-calculate-responsible')" divided>Назначить ответственного
              </el-dropdown-item>
              <el-dropdown-item @click="addAction('calendar-validate-responsible')">Проверка ответственного
              </el-dropdown-item>
              <el-dropdown-item @click="addAction('auto-reply')" divided>Автоответ</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </Panel>

      <Background/>
      <template #node-start="props">
        <LinkerActionStart :id="props.id" :data="props.data"/>
      </template>
      <template #node-amocrm-find-contact="props">
        <LinkerActionFindContact :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                                 :scenario="scenario"/>
      </template>
      <template #node-amocrm-find-lead="props">
        <LinkerActionFindLead :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                              :scenario="scenario"/>
      </template>
      <template #node-amocrm-find-task="props">
        <LinkerActionFindTask :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                              :scenario="scenario"/>
      </template>
      <template #node-amocrm-create-lead="props">
        <LinkerActionCreateLead :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                                :scenario="scenario"/>
      </template>
      <template #node-amocrm-create-contact="props">
        <LinkerActionCreateContact :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                                   :scenario="scenario"/>
      </template>
      <template #node-amocrm-create-task="props">
        <LinkerActionCreateTask :data="props.data" :errors="errors" :index="`Scenario.Actions[${index(props.id)}]`"
                                :scenario="scenario"/>
      </template>
      <template #node-amocrm-change-responsible="props">
        <LinkerActionAmocrmChangeResponsible :data="props.data" :errors="errors"
                                             :index="`Scenario.Actions[${index(props.id)}]`" :scenario="scenario"/>
      </template>
      <template #node-calendar-calculate-responsible="props">
        <LinkerActionCalendarCalculateResponsible :data="props.data" :errors="errors"
                                                  :index="`Scenario.Actions[${index(props.id)}]`" :scenario="scenario"/>
      </template>
      <template #node-calendar-validate-responsible="props">
        <LinkerActionCalendarValidateResponsible :data="props.data" :errors="errors"
                                                 :index="`Scenario.Actions[${index(props.id)}]`" :scenario="scenario"/>
      </template>
      <template #node-auto-reply="props">
        <LinkerActionAutoReply :data="props.data" :errors="errors"
                               :index="`Scenario.Actions[${index(props.id)}]`" :scenario="scenario"/>
      </template>
    </VueFlow>
  </div>
</template>

<script>
export default {
  name: 'LinkerFlow',
}
</script>

<script setup>
import {Panel, VueFlow, ConnectionMode, useVueFlow} from '@vue-flow/core'
import {Controls} from '@vue-flow/controls'


import {Background} from '@vue-flow/background'
import '@vue-flow/controls/dist/style.css'


import {defineProps, computed} from 'vue';
import LinkerActionFindContact
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionFindContact";
import LinkerActionFindLead
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionFindLead";
import LinkerActionStart from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionStart";
import LinkerActionAmocrmChangeResponsible
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionAmocrmChangeResponsible";
import LinkerActionCreateContact
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionCreateContact";
import LinkerActionCreateLead
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionCreateLead";
import LinkerActionCreateTask
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionCreateTask";
import {Errors} from "@/utils/errors";
import LinkerActionCalendarCalculateResponsible
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionCalendarCalculateResponsible";
import LinkerActionFindTask from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionFindTask";
import LinkerActionCalendarValidateResponsible
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionCalendarValidateResponsible";
import LinkerActionAutoReply
  from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow/LinkerActionAutoReply";

const {
  onNodesChange,
  onEdgesChange,
  onConnect,
  addEdges,
  addNodes,
} = useVueFlow()

onEdgesChange((updateEdges) => {
  let edges = edgesValue.value
  updateEdges.forEach((updateEdge) => {
    switch (updateEdge.type) {
      case "add": {
        console.log("add", updateEdge, edges)
        let id = -(edges.length + 1)
        let edge = {
          id: id.toString(),
          source: updateEdge.item.source,
          sourceHandle: updateEdge.item.sourceHandle,
          target: updateEdge.item.target,
          targetHandle: updateEdge.item.targetHandle,
        }
        edges.push(edge)
        break;
      }
      case "remove": {
        console.log("remove", updateEdge, edges)
        let index = edges.findIndex((edge) => edge.id === updateEdge.id);
        if (index >= 0) {
          edges.splice(index, 1);
        }
      }
    }
  })
  console.log(updateEdges)
})

onNodesChange((updateNodes) => {
  let nodes = nodesValue.value
  updateNodes.forEach((updateNode) => {
    switch (updateNode.type) {
      case "position": {
        console.log("position", updateNode, nodes)
        if (updateNode.dragging) {
          let index = nodes.findIndex(node => node.id === updateNode.id)
          if (index >= 0) {
            nodes[index].position.x = updateNode.position.x
            nodes[index].position.y = updateNode.position.y
          }
        }
        break;
      }
      case "add": {
        console.log("add", updateNode, nodes)
        let node = {
          id: updateNode.item.id,
          type: updateNode.item.type,
          position: updateNode.item.position,
          data: updateNode.item.data,
        }
        nodes.push(node)
        break;
      }
      case "remove": {
        console.log("remove", updateNode, nodes)
        let index = nodes.findIndex((node) => node.id === updateNode.id);
        if (index >= 0) {
          nodes.splice(index, 1);
        }
      }
    }
  })
})

onConnect((params) => addEdges([params]))

const nodesValue = computed(() => {
  return props.nodes || []
})

const edgesValue = computed(() => {
  return props.edges || []
})

const props = defineProps({
  nodes: Array,
  edges: Array,
  errors: Errors,
  scenario: Object,
})

const index = function (id) {
  return nodesValue.value.findIndex(node => node.id.toString() === id) || 0
}

const addAction = function (type) {
  console.log(nodesValue, type)
  let id = -(nodesValue.value.length + 1)
  let position = {x: 200, y: 300}

  addNodes([{
    id: id.toString(),
    type: type,
    position: position,
    data: {},
  }])
}
</script>
