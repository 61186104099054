<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button type="primary" @click="openAddDistribution" size="small">Добавить распределение</el-button>
    </el-row>
    <el-row>
      <el-table style="width: 100%" :data="calendar_distributions">
        <el-table-column prop="id" label="ID" width="180"/>
        <el-table-column prop="name" label="Название" width="180"/>
        <el-table-column prop="type" label="Тип" width="180">
          <template #default="scope">
            {{ types[scope.row.type] || scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column prop="users" label="Пользователи" width="180">
          <template #default="scope">
            {{ scope.row.users.map((u) => `${userName(u.user_id)} (${u.coefficient})`).join(", ") }}
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="right">
          <template #default="scope">
            <el-button :icon="EditPen" circle @click="openEditDistributionForm(scope.row)"/>
            <el-popconfirm title="Удалить?"
                           confirm-button-text="Да"
                           confirm-button-type="danger"
                           cancel-button-text="Нет"
                           :icon="InfoFilled"
                           @confirm="deleteDistribution(scope.row.id)">
              <template #reference>
                <el-button type="danger" :icon="Delete" circle/>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-dialog
        v-model="calendarDistributionFormVisible"
        title="Распределение"
        :width="700"
    >
      <el-form label-position="top"
               :model="calendarDistributionForm.object" size="small"
               :rules="calendarDistributionRules"
               ref="calendarDistributionRef"
      >
        <el-form-item label="Название" prop="name" style="width: 100%;"
                      :error="errors.get('Name')">
          <el-input v-model="calendarDistributionForm.object.name"></el-input>
        </el-form-item>
        <el-form-item label="Тип распределения" prop="type" style="width: 100%;"
                      :error="errors.get('Type')">
          <el-select v-model="calendarDistributionForm.object.type">
            <el-option
                v-for="(value, key) in types"
                :key="key"
                :label="value"
                :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Пользователи" prop="users" style="width: 100%;"
                      :error="errors.get('Users')">
          <el-button style="margin-bottom: 10px" @click="addDistributionUser">Добавить пользователя
          </el-button>
          <el-table :data="calendarDistributionForm.object.users" style="width: 100%">
            <el-table-column label="Имя" width="200">
              <template #default="scope">
                <el-select v-model="scope.row.user_id" size="small">
                  <el-option
                      v-for="user in calendar_users"
                      :key="user.id"
                      :label="user.name"
                      :value="user.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="coefficient" label="Коэффициент" width="150">
              <template #default="scope">
                <el-form-item prop="coefficient" :error="errors.get('Coefficient')">
                  <el-input-number v-model="scope.row.coefficient"
                                   :disabled="calendarDistributionForm.object.type!=='coefficient'"></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date_last_distribution" label="Последнее распределение" width="150"/>
            <el-table-column min-width="30" align="right">
              <template #default="scope">
                <el-button type="danger" :icon="Delete" circle @click="deleteDistributionUser(scope.$index)"/>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item>
          <el-button v-if="calendarDistributionForm.origin.id" :disabled="calendarDistributionForm.isEqual()"
                     type="primary" @click="editDistribution">Сохранить
          </el-button>
          <el-button v-else type="primary" @click="addDistribution">Добавить</el-button>
          <el-button @click="calendarDistributionFormVisible = false">Отмена</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>import {Delete, EditPen, InfoFilled} from '@element-plus/icons-vue'</script>

<script>
import {FormObject} from "@/utils/form-object";
import {Errors} from "@/utils/errors";
import {ADD_CALENDAR_DISTRIBUTION, DELETE_CALENDAR_DISTRIBUTION, UPDATE_CALENDAR_DISTRIBUTION} from "@/store/actions";

export default {
  name: 'LinkerCalendarDistribution',
  props: {
    calendar: Object,
  },
  data() {
    return {
      errors: new Errors(),
      calendarDistributionFormVisible: false,
      calendarDistributionForm: new FormObject(),
      calendarDistributionRules: {},

      types: {
        queue: "По очереди",
        coefficient: "По коэффициентам",
      },
    }
  },
  methods: {
    openAddDistribution() {
      this.calendarDistributionForm = new FormObject({type: "coefficient"})
      this.calendarDistributionFormVisible = true
    },
    addDistribution() {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      let data = this.calendarDistributionForm.object
      this.$store.dispatch(ADD_CALENDAR_DISTRIBUTION, {pid, cid, data}).then(() => {
        this.calendarDistributionFormVisible = false
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    openEditDistributionForm(distribution) {
      this.calendarDistributionForm = new FormObject(distribution)
      this.calendarDistributionFormVisible = true
    },
    editDistribution() {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      let cdid = this.calendarDistributionForm.origin.id
      let data = this.calendarDistributionForm.object
      this.$store.dispatch(UPDATE_CALENDAR_DISTRIBUTION, {pid, cid, cdid, data}).then(() => {
        this.calendarDistributionFormVisible = false
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    deleteDistribution(cdid) {
      let pid = this.calendar.project_id
      let cid = this.calendar.id
      this.$store.dispatch(DELETE_CALENDAR_DISTRIBUTION, {pid, cid, cdid}).then((resp) => {
        console.log(resp)
      })
    },

    addDistributionUser() {
      if (!Array.isArray(this.calendarDistributionForm.object.users)) {
        this.calendarDistributionForm.object.users = []
      }
      this.calendarDistributionForm.object.users.push({coefficient: 1})
    },
    deleteDistributionUser(index) {
      this.calendarDistributionForm.object.users.splice(index, 1);
    },

    updateDistribution() {
    },
    userName(id) {
      return (this.calendar_users.find(user => user.id === id) || {}).name || "Пользователь удален"
    }
  },
  computed: {
    calendar_distributions() {
      return this.$store.getters.calendar_distributions
    },
    calendar_users() {
      return this.$store.getters.calendar_users
    },
  }
}
</script>

<style></style>
