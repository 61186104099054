<template>
  <div>
    <el-row style="margin-bottom: 15px">
      <el-col>
        <el-button type="primary" @click="openAddScenarioForm">Добавить сценарий</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table style="width: 100%" :data="scenarios">
          <el-table-column prop="id" label="ID" width="180"/>
          <el-table-column prop="name" label="Название" width="180"/>
          <el-table-column prop="type" label="Тип"/>
          <el-table-column min-width="100" align="right">
            <template #default="scope">
              <el-button :icon="EditPen" circle @click="openEditScenarioForm(scope.row)"/>
              <el-popconfirm title="Удалить?"
                             confirm-button-text="Да"
                             confirm-button-type="danger"
                             cancel-button-text="Нет"
                             :icon="InfoFilled"
                             @confirm="deleteScenario(scope.row.id)">
                <template #reference>
                  <el-button type="danger" :icon="Delete" circle/>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="scenarioFormVisible"
        title="Добавление сценария"
        :width="1300"
        class="dialog-scenario"
    >
      <el-row style="height: 100%">
        <el-col :span="4" style="padding-right: 15px">
          <el-form size="small" label-position="top">
            <el-row>
              <el-form-item label="Название" prop="name" style="width: 100%;" :error="errors.get('Scenario.Name')">
                <el-input v-model="scenarioForm.object.name"/>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Активен" prop="is_active">
                <el-switch v-model="scenarioForm.object.is_active"/>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Интеграция-источник" prop="source_id" style="width: 100%;"
                            :error="errors.get('Scenario.SourceId')">
                <el-select
                    :model-value="scenarioForm.object.source_type && scenarioForm.object.source_id ? `${scenarioForm.object.source_type}:${scenarioForm.object.source_id}` : ''"
                    @change="scenarioForm.object.source_type=$event.split(':')[0]; scenarioForm.object.source_id=$event.split(':')[1]">
                  <el-option
                      v-for="integration in source_integrations"
                      :key="`${integration.type}:${integration.id}`"
                      :label="integration.name + ' (' + integration.type + ')'"
                      :value="`${integration.type}:${integration.id}`"
                  />
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="События" prop="event_types" style="width: 100%;"
                            :error="errors.get('Scenario.EventTypes')">
                <el-select
                    style="width: 100%"
                    :collapse-tags="true"
                    :modelValue="scenarioForm.object.event_types.length === 0 ? [] : scenarioForm.object.event_types.split(',')"
                    @update:modelValue="$event => (scenarioForm.object.event_types = $event === '' ? $event : $event.join(','))"
                    multiple>
                  <el-option
                      v-for="(value, key) in event_types[scenarioForm.object.source_type] || {}"
                      :key="key"
                      :label="value"
                      :value="key"
                  />
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="CRM-система" prop="target_id" style="width: 100%;"
                            :error="errors.get('Scenario.TargetId')">
                <el-select
                    :model-value="scenarioForm.object.target_type && scenarioForm.object.target_id ? `${scenarioForm.object.target_type}:${scenarioForm.object.target_id}` : ''"
                    @change="scenarioForm.object.target_type=$event.split(':')[0]; scenarioForm.object.target_id=$event.split(':')[1]">
                  <el-option
                      v-for="integration in target_integrations"
                      :key="`${integration.type}:${integration.id}`"
                      :label="integration.name + ' (' + integration.type + ')'"
                      :value="`${integration.type}:${integration.id}`"
                  />
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Канал используется" prop="channel_is_active">
                <el-switch v-model="scenarioForm.object.channel_is_active"/>
              </el-form-item>
            </el-row>
            <el-row>
              <el-button @click="scenarioForm.reset()" :disabled="scenarioForm.isEqual()">Отмена</el-button>
              <el-button v-if="scenarioForm.origin.id" type="primary" @click="updateScenario"
                         :disabled="scenarioForm.isEqual()">Сохранить
              </el-button>
              <el-button v-else type="primary" @click="addScenario">
                Добавить
              </el-button>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="20" class="flow-border">
          <linker-flow :nodes="scenarioForm.object.actions" :edges="scenarioForm.object.edges"
                       :errors="errors" :scenario="scenarioForm.origin" style="width: 100%"/>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>


<script setup>import {Delete, EditPen, InfoFilled} from '@element-plus/icons-vue'</script>

<script>
import {Errors} from "@/utils/errors";
import {
  ADD_SCENARIO,
  DELETE_SCENARIO,
  GET_SCENARIO,
  UPDATE_SCENARIO
} from "@/store/actions";
import {FormObject} from "@/utils/form-object";
import '@vue-flow/core/dist/style.css'
import "@vue-flow/core/dist/theme-default.css";
import LinkerFlow from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerFlow";


export default {
  name: 'LinkerScenario',
  components: {LinkerFlow},
  data() {
    return {
      errors: new Errors(),
      scenarioFormVisible: false,
      scenarioForm: new FormObject(),

      event_types: {
        avito: {
          in: "Входящее сообщение",
          out: "Исходящее сообщение",
        }
      },
    }
  },
  methods: {
    openAddScenarioForm() {
      this.errors.clear()
      this.scenarioForm = new FormObject({
        id: 0,
        name: "",
        source_type: "",
        source_id: "",
        event_types: "",
        target_type: "",
        target_id: "",
        channel_is_active: false,
        is_active: true,
        actions: [{
          id: "-1",
          type: "start",
          position: {x: 50, y: 200},
          data: {},
        }],
        edges: [],
      })
      this.scenarioFormVisible = true;
    },
    openEditScenarioForm(scenario) {
      this.errors.clear()
      let sid = scenario.id
      let pid = scenario.project_id
      this.$store.dispatch(GET_SCENARIO, {pid, sid}).then((resp) => {
        this.scenarioForm = new FormObject(resp.data.data)
        this.scenarioFormVisible = true;
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    addScenario() {
      this.errors.clear()
      this.$store.dispatch(ADD_SCENARIO, {
        project_id: this.$route.params.project_id,
        data: this.scenarioForm.object,
      }).then((resp) => {
        this.scenarioForm = new FormObject(resp.data.data)
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    updateScenario() {
      this.errors.clear()
      this.$store.dispatch(UPDATE_SCENARIO, {
        project_id: this.$route.params.project_id,
        scenario_id: this.scenarioForm.origin.id,
        data: this.scenarioForm.object,
      }).then((resp) => {
        this.scenarioForm = new FormObject(resp.data.data)
      }).catch((err) => {
        this.errors.record(err.response.data.errors);
      })
    },
    deleteScenario(sid) {
      let pid = this.$route.params.project_id
      this.$store.dispatch(DELETE_SCENARIO, {pid, sid}).then((resp) => {
        console.log(resp)
      })
    },
  },
  computed: {
    scenarios() {
      return this.$store.getters.scenarios
    },
    target_integrations() {
      return this.$store.getters.integrations.filter(i => ["amocrm"].includes(i.type))
    },
    source_integrations() {
      return this.$store.getters.integrations.filter(i => ["avito"].includes(i.type))
    },
  }
}
</script>

<style>
.dialog-scenario {
  margin-top: 50px;
  height: 90%
}

.dialog-scenario .el-dialog__body {
  height: calc(100vh - 165px);
}

.flow-border {
  border: solid 1px lightgrey;
  border-radius: 5px;
}
</style>
