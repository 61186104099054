<template>
  <el-card>
    <div>Начало сценария</div>
    <Handle
        id="out"
        type="source"
        :position="Position.Right"
        :connectable="1"/>
  </el-card>
</template>

<script>
export default {
  name: 'LinkerActionStart'
}
</script>

<script setup>
import {Handle, Position} from '@vue-flow/core'
</script>