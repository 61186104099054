<template>
  <div>
    <el-row style="margin-bottom: 15px">
      <el-col>
        <el-button type="primary" @click="integrationListVisible = true">Добавить интеграцию</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table style="width: 100%" :data="integrations">
          <el-table-column prop="id" label="ID" width="180"/>
          <el-table-column prop="name" label="Название" width="180"/>
          <el-table-column prop="type" label="Тип"/>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="integrationListVisible"
        title="Выберите тип интеграции"
        width="500"
    >
      <el-table :data="integrationList" @row-click="getUrlConnect" :show-header="false">
        <el-table-column>
          <template #default="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {GET_INTEGRATION_CONNECT_URL} from "@/store/actions";
import {PUSH_INTEGRATION} from "@/store/mutations";

export default {
  name: 'LinkerIntegration',
  data() {
    return {
      integrationListVisible: false,
      integrationList: [
        {type: "amocrm"},
        {type: "avito"},
        {type: "vk"},
        {type: "senler"},
      ]
    }
  },
  methods: {
    getUrlConnect(row) {
      let project_id = this.$route.params.project_id;
      let type = row.type;
      this.$store.dispatch(GET_INTEGRATION_CONNECT_URL, {project_id, type}).then((resp) => {
            console.log(resp)
            let url = resp.data;
            if (url) {
              let title = "Предоставить доступ для интеграции";
              let w = 750;
              let h = 580;
              let dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : screen.left;
              let dual_screen_top = window.screenTop !== undefined ? window.screenTop : screen.top;
              let width = window.innerWidth
                  ? window.innerWidth
                  : (document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width);

              let height = window.innerHeight
                  ? window.innerHeight
                  : (document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height);

              let left = ((width / 2) - (w / 2)) + dual_screen_left;
              let top = ((height / 2) - (h / 2)) + dual_screen_top;

              let popup = window.open(url, title, 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

              let handler = (resp) => {
                if (resp.data.event_type === 'integration_connection') {
                  switch (resp.data.status) {
                    case 200:
                      this.$store.commit(PUSH_INTEGRATION, resp.data.response.integration)
                  }
                  window.removeEventListener('message', handler);
                  popup.close();
                }
              };
              window.addEventListener('message', handler);
            }
            this.integrationListVisible = false
          }
      ).catch()
    },
  },
  computed: {
    integrations() {
      return this.$store.getters.integrations
    }
  }
}
</script>

<style scoped>
</style>
