<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (поиск контакта)</div>
    </template>

    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="action.find_by_phone" label="Поиск по телефону" size="small"/>
        <el-checkbox v-model="action.find_by_email" label="Поиск по email" size="small"/>
      </el-form-item>
      <el-form-item label="Поиск профилю VK" :error="errors.get(index + '.Data.VkProfileFieldId')">
        <el-select v-model="action.vk_profile_field_id" size="small">
          <el-option
              v-for="item in customFields"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Поиск профилю Avito" :error="errors.get(index + '.Data.AvitoProfileFieldId')">
        <el-select v-model="action.avito_profile_field_id" size="small">
          <el-option
              v-for="item in customFields"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Фильтр по ответственному" :error="errors.get(index + '.Data.FilterByResponsibleUsers')">
        <el-select v-model="action.filter_by_responsible_users" size="small" multiple :collapse-tags="true">
          <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Способ выбора контакта" :error="errors.get(index + '.Data.SelectionMethod')">
        <el-select v-model="action.selection_method" size="small">
          <el-option
              v-for="item in methods"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="found"
        type="source"
        :position="Position.Right" style="top: 30%; background: green;"
        :connectable="1"/>
    <Handle
        id="not-found"
        type="source"
        :position="Position.Right" style="top: 70%; background: red;"
        :connectable="1"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>
  </el-card>
</template>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionFindContact',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      methods: [{
        value: 'last-create',
        label: 'Последний созданный'
      }, {
        value: 'last-update',
        label: 'Последний обновленный'
      }]
    }
  },
  computed: {
    action() {
      return this.data
    },
    account() {
      return (this.$store.getters.integrations.find(i => i.type === this.scenario.target_type && i.id === this.scenario.target_id) || {}).account || {}
    },
    users() {
      return this.account.users || []
    },
    customFields() {
      return this.account.custom_fields || []
    },
  }
}
</script>

<script setup>import {Handle, Position} from '@vue-flow/core'</script>
