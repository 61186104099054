<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (назначить ответственного)</div>
    </template>
    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item label="Календарь"
                    :error="errors.get(index + '.Data.CalendarId')">
        <el-select v-model="action.calendar_id" size="small">
          <el-option
              v-for="item in calendars"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Распределение"
                    :error="errors.get(index + '.Data.DistributionId')">
        <el-select v-model="action.distribution_id" size="small">
          <el-option
              v-for="item in distributions(action.calendar_id)"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Период определения, мин" :error="errors.get(index + '.Data.Period')">
        <el-input-number v-model="action.period" :controls="false" size="small"/>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>
  </el-card>
</template>


<script setup>import {Handle, Position} from '@vue-flow/core'</script>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionCalendarCalculateResponsible',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      distribution_types: [
        {
          value: 'fixed',
          label: 'Фиксированный пользователь'
        },
        {
          value: 'calendar',
          label: 'Календарь'
        }
      ]
    }
  },
  methods: {
    distributions(calendar_id) {
      return (this.calendars.find(calendar => calendar.id === calendar_id) || {}).distributions || []
    }
  },
  computed: {
    action() {
      return this.$props.data
    },
    calendars() {
      return this.$store.getters.calendars
    },
  }
}
</script>

