<template>
  <el-card style="width: 300px;">
    <template #header>
      <div>{{ action.name }} (автоответ)</div>
    </template>
    <el-form label-position="top">
      <el-form-item label="Название" :error="errors.get(index + '.Data.Name')">
        <el-input v-model="action.name" size="small"/>
      </el-form-item>
      <el-form-item label="Условие отправки" :error="errors.get(index + '.Data.Condition')">
        <el-select v-model="action.condition" size="small">
          <el-option
              v-for="item in conditions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Текст" :error="errors.get(index + '.Data.Text')">
        <el-input v-model="action.text" :rows="2" type="textarea"/>
      </el-form-item>
      <el-form-item v-if="action.condition==='not-work'" label="Календарь"
                    :error="errors.get(index + '.Data.CalendarId')">
        <el-select v-model="action.calendar_id" size="small">
          <el-option
              v-for="item in calendars"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="action.condition==='not-work'" label="Распределение"
                    :error="errors.get(index + '.Data.DistributionId')">
        <el-select v-model="action.distribution_id" size="small">
          <el-option
              v-for="item in distributions(action.calendar_id)"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <Handle
        id="in"
        type="target"
        :position="Position.Left"/>
    <Handle
        id="sent"
        type="source"
        :position="Position.Right" style="top: 30%; background: green;"
        :connectable="1"/>
    <Handle
        id="not-sent"
        type="source"
        :position="Position.Right" style="top: 70%; background: red;"
        :connectable="1"/>
    <Handle
        id="out"
        type="source"
        :position="Position.Bottom" style="left: 100%"
        :connectable="1"/>
  </el-card>
</template>


<script setup>import {Handle, Position} from '@vue-flow/core'</script>

<script>
import {Errors} from "@/utils/errors";

export default {
  name: 'LinkerActionAutoReply',
  props: {
    data: Object,
    errors: Errors,
    index: String,
    scenario: Object,
  },
  data() {
    return {
      conditions: [{
        value: 'first',
        label: 'На первое входящее сообщение'
      }, {
        value: 'not-work',
        label: 'На сообщение в нерабочее время'
      }],
    }
  },
  methods: {
    distributions(calendar_id) {
      return (this.calendars.find(calendar => calendar.id === calendar_id) || {}).distributions || []
    }
  },
  computed: {
    action() {
      return this.data
    },
    calendars() {
      return this.$store.getters.calendars
    },
  }
}
</script>