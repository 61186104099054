<template>
  <div>
    <el-form-item label="Поиск по телефону">
      <el-switch v-model="valueAction.action_amocrm_find_contact.find_by_phone"/>
    </el-form-item>
    <el-form-item label="Поиск по email">
      <el-switch v-model="valueAction.action_amocrm_find_contact.find_by_email"/>
    </el-form-item>

    <linker-actions header="Контакт не найден" :actions="valueAction.actions"
                    :condition="0"/>
    <linker-actions header="Контакт найден" :actions="valueAction.actions" :condition="1"/>
  </div>
</template>

<script>
import LinkerActions from "@/components/LinkerMain/LinkerProject/LinkerScenario/LinkerActions";

export default {
  name: 'LinkerActionFindContact',
  components: {LinkerActions},
  props: ["action"],
  computed: {
    valueAction() {
      let value = this.action
      if (!Object.prototype.hasOwnProperty.call(value, "action_amocrm_find_contact")) {
        value.action_amocrm_find_contact = {}
      }
      return value
    },
  }
}
</script>

<style>
</style>
