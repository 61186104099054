export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESTORE = 'RESTORE';
export const GET_RESTORE_CODE = 'GET_RESTORE_CODE';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const SAVE_USER = 'SAVE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const GET_PROJECT = 'GET_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const PAY = 'PAY';

export const ADD_RIGHT = 'ADD_RIGHT';
export const SAVE_RIGHT = 'SAVE_RIGHT';
export const DELETE_RIGHT = 'DELETE_RIGHT';

export const CONNECT_AMOCRM = 'CONNECT_AMOCRM';
export const SYNC_AMOCRM = 'SYNC_AMOCRM';
export const DISCONNECT_AMOCRM = 'DISCONNECT_AMOCRM';

export const GET_INTEGRATION = 'GET_INTEGRATION';
export const SAVE_INTEGRATION = 'SAVE_INTEGRATION';
export const CHECK_INTEGRATION = 'CHECK_INTEGRATION';
export const GET_INTEGRATION_CONNECT_URL = 'GET_INTEGRATION_CONNECT_URL';

export const GET_SCENARIO = 'GET_SCENARIO';
export const ADD_SCENARIO = 'ADD_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';

export const GET_CALENDAR = 'GET_CALENDAR';
export const ADD_CALENDAR = 'ADD_CALENDAR';
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';
export const DELETE_CALENDAR = 'DELETE_CALENDAR';
export const SYNC_CALENDAR_AMOCRM_USERS = 'SYNC_CALENDAR_AMOCRM_USERS';

export const ADD_CALENDAR_DISTRIBUTION = 'ADD_CALENDAR_DISTRIBUTION';
export const UPDATE_CALENDAR_DISTRIBUTION = 'UPDATE_CALENDAR_DISTRIBUTION';
export const DELETE_CALENDAR_DISTRIBUTION = 'DELETE_CALENDAR_DISTRIBUTION';

export const ADD_CALENDAR_SCHEDULE = 'ADD_CALENDAR_SCHEDULE';
export const UPDATE_CALENDAR_SCHEDULE = 'UPDATE_CALENDAR_SCHEDULE';
export const DELETE_CALENDAR_SCHEDULE = 'DELETE_CALENDAR_SCHEDULE';

export const ADD_CALENDAR_USER_SCHEDULE = 'ADD_CALENDAR_USER_SCHEDULE';
export const UPDATE_CALENDAR_USER_SCHEDULE = 'UPDATE_CALENDAR_USER_SCHEDULE';
export const DELETE_CALENDAR_USER_SCHEDULE = 'DELETE_CALENDAR_USER_SCHEDULE';